import HeaderComp from './components/headerComp';
import Banner from './components/banner';
import Footer from './components/footer';
import './App.css';

function App() {
  return (
    <div>
      <div className="venumonkApp">
        <div>
        <HeaderComp/>
        <Banner/>
        <Footer/>
        </div>
    </div>
    </div>
  );
}

export default App;
