import React from 'react';
function Footer (props, context) 
{
    return (
        <div className='footer'>
        
        <p className='disclaimerFooter' style={{paddingTop:'20px', paddingBottom: '10px', marginBottom: '0px'}} >*Disclaimer<br/>VenueMonk Technologies Private Limited<br/>(Known as VenueMonk)<br/>
        <br/>CIN: U72900UP2022PTC161065<br/>
        <br/>Registered Office - Plot No. 90B, Sector 18, Gurugram - Haryana<br/> Gurgaon Haryana 122001 India<br/>
        <br/>VenueMonk.com is an online venue booking portal with the widest range of venues available in Delhi NCR, Gurgaon, Noida, Faridabad, and Ghaziabad. At VenueMonk, we connect our customers with the best venues in their city based on their unique requirements so that they can get the ideal deal for their event. We believe that online venue booking is just as personal as offline venue booking, except much simpler and our goal is to provide hassle-free online venue booking experience to our customers, all the way from browsing and shortlisting venues to booking the final one.
        <br/><br/>© Copyright 2022 VenueMonk.com. All Rights
Reserved<br/>
        </p>
         
        </div>
    )
}

export default Footer;