// import { render } from '@testing-library/react';
import React from 'react';
import callApi, {callEventApi} from '../apiCaller';
// import Popup from 'reactjs-popup';
import moment from 'moment';


export default class Banner extends React.Component {
    constructor() {
        super();
        this.state = {
            name: '',
            phone: '',
            email: '',
            isOn: true,
        }
        this.updateState = this.updateState.bind(this)
    }

    componentDidMount() {
        let userID = ''
        let campaignID = ''
        localStorage.setItem("startTime", JSON.stringify(moment().toDate()))
        const params = new URLSearchParams(window.location.search)
        for (const param of params) {
            userID = params.get('m');
            campaignID = params.get('c');
        }
        callApi(`api/campaign/landing-page-campaign?campaignid=${campaignID}&magic_token=${userID}`, 'GET')
            .then(data1 => {
                console.log(data1, 'data1')
                this.setState({
                    name: data1.data.event.contact_name,
                    email: data1.data.event.contact_email,
                    phone: data1.data.event.contact_phone
                })
            })
            .catch(err => {
                if (err) {
                    this.updateState(true)
                }
            })
    }

    updateState(val) {
        // Changing state
        this.setState({isOn: val})
    }


    submit = () => {
        const body = {
            "occasion": "Birthday Party",
            "contact_name": this.state.name,
            "contact_phone": this.state.phone,
            "contact_email": this.state.email,
            "country_code": "91",
            "source": 'webapp',
            "media": null,
            "campaign": 'SMS',
            "other_source": null,
            "lead_source": "Birthday-landing-page",
            "referral": "",
            "leadFormStats": {
                "landingPage": "/",
                "path": "/",
                "source_app": "birthday-landing-page-source-webapp",
                "submitDiff": this.diffTime,
                "popupCount": "1"
            },
            "date": moment().toDate().getTime(),
            "city": "" //
        }
        const getStartTime = JSON.parse(localStorage.getItem("startTime"));
        const diffTime = moment().diff(getStartTime, 'seconds')


        console.log(diffTime, 'diffTime')

        callEventApi('api/customerevent/event/plan?lp=birthday-lp', 'POST', body)
            .then(data => {
                console.log(data, 'data')
                this.updateState(false);
            })
    }


    render() {
        return (
            this.state.isOn ?

                <div style={{paddingBottom: '20px'}}>
                    {/* <div> */}
                    <div style={{margin: 10, marginBottom: -10}}>
                        <p>Name</p>
                    </div>
                    <div style={{textAlignLast: 'center'}}>
                        <input type='text' name='name' onChange={(data) => {
                            this.setState({name: data.target.value})
                        }}
                               style={{
                                   paddingTop: 8,
                                   paddingBottom: 5,
                                   width: '93%',
                                   font: 'Poppins',
                                   fontSize: '14px',
                                   textAlignLast: 'left',
                                   paddingLeft: '3px'
                               }}
                               value={this.state.name}>
                        </input>
                    </div>
                    <div style={{margin: 10, marginBottom: -10}}>
                        <p>Email</p>
                    </div>
                    <div style={{textAlignLast: 'center'}}>
                        <input type='text' name='email' onChange={(data) => {
                            this.setState({email: data.target.value})
                        }}
                               style={{
                                   paddingTop: 8,
                                   paddingBottom: 5,
                                   width: '93%',
                                   font: 'Poppins',
                                   fontSize: '14px',
                                   textAlignLast: 'left',
                                   paddingLeft: '3px'
                               }}
                               value={this.state.email}>
                        </input>
                    </div>
                    <div style={{margin: 10, marginBottom: -10}}>
                        <p>Mobile No</p>
                    </div>
                    <div style={{textAlignLast: 'center'}}>
                        <input type='text' name='phone' onChange={(data) => {
                            this.setState({phone: data.target.value})
                        }}
                               style={{
                                   paddingTop: 8,
                                   paddingBottom: 5,
                                   width: '93%',
                                   font: 'Poppins',
                                   fontSize: '14px',
                                   textAlignLast: 'left',
                                   paddingLeft: '3px'
                               }}
                               value={this.state.phone}>
                        </input>
                    </div>
                    {/* </div> */}
                    <div style={{textAlignLast: 'center', paddingTop: '24px'}}>
                        <button onClick={() => this.submit()} className='PartyButton'>
                            <img src='https://cdn.venuemonk.com/party-icon.png' alt='party '
                                 style={{height: '20px', paddingRight: '8px'}}/>
                            Let's Party
                        </button>
                    </div>
                    {/* </div> */}
                </div>
                :
                <div>
                    <div className='FontsStyle1'><strong>
                        Thank you </strong><br/> we'll get back too you soon
                    </div>
                </div>

        );
    }
}
