import React from 'react';

function HeaderComp () {
    return (
        <div>
            <div className='FontsStyle'>
                <div>
                        <img src= 'https://cdn.venuemonk.com/birthday-party-banner.webp' alt='party' className='bg' />            
                </div>
                <div style={{ paddingTop: '5px' }}>
                        <div style={{ fontSize:'min(6vw, 36px)'}}> Birthday Party </div>
                        <div style={{ paddingBottom: '15', fontSize:'min(4vw, 20px)' }}>It’s almost your birthday! <br /> Time to Celebrate
                        </div>
                </div>
         </div>

        </div>
    );
  }
  
  export default HeaderComp;

  
    